import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

const Service = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [show, setShow] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = (service) => {
    setSelectedService(service);
    setShow(true);
  };

  const servicesData = [  {
    id: 1,
    image: '/assets/images/service_imgs/business-setup.jpeg',
    title: 'UAE Business Setup & PRO Services',
    description:
      'Start and grow your business in the UAE with our expert business setup services. We assist with company formation in Ajman, Dubai Mainland, all Free Zones, and Offshore jurisdictions. Our services include trade license registration and renewal, PRO services, and complete business compliance support. From choosing the right setup to handling government approvals, we ensure a smooth and cost-effective process for your business success.',
  },
    {
      id: 2,
      image: '/assets/images/service_imgs/tex.png',
      title: 'Corporate Tax Consulting',
      description:
        'At EBMC, we specialize in providing end-to-end Corporate Tax Solutions designed to ensure compliance, minimize risks, and optimize your tax position. From registration and audits to filing and compliance, our expert team handles it all. We also offer personalized consultation to address your doubts and provide clarity on corporate tax matters.Let us take the complexity out of corporate tax so you can focus on growing your business.',
    },
    {
      id: 3,
      image: '/assets/images/service_imgs/aml.png',
      title: 'AML Compliance Solutions',
      description:
        'Ensure your business meets all Anti-Money Laundering (AML) regulations with our comprehensive compliance services. We provide AML registration, customized AML policy setup, ongoing compliance support, AML training for your team, and thorough AML audits. Stay compliant, avoid penalties, and protect your business with our expert solutions.',
    },
    {
      id: 4,
      image: '/assets/images/service_imgs/vat.png',
      title: 'UAE VAT Compliance & Advisory Services',
      description:
        'Ensure seamless VAT compliance with our expert UAE VAT services. We handle VAT registration, quarterly VAT reporting, and filing with the FTA portal. Our team provides VAT audits, compliance checks, and VAT deregistration assistance. If you\'ve incurred penalties, we also offer reconsideration services to help reduce or waive fines. Stay compliant and avoid costly mistakes with our professional VAT solutions.',
    },
    {
      id: 5,
      image: '/assets/images/service_imgs/CPA Insurance for Accountants_ Cost, Coverage & Providers.png',
      title: 'Software Implementation & Accounting Integration',
      description:
        'Streamline your business operations with our expert software implementation services. We set up and integrate ERP and accounting software with accurate opening balances and financial statements. As authorized partners of leading software providers, we ensure seamless implementation, training, and direct support. Our dedicated team helps you optimize software solutions for smooth financial management and business growth.',
    },
  
    {
      id: 6,
      image: '/assets/images/service_imgs/Accounting stock photo_ Image of economy, currency, bills - 1868862.png',
      title: 'Accounting & Financial Audit Services',
      description:
        'Ensure financial transparency and compliance with our expert accounting and audit services. We provide professional bookkeeping, monthly accounting, financial analysis, and MSI reporting. Our team delivers management reports, feasibility studies, and projections for new businesses. We also conduct legal, government, bank, and court-approved financial audits to support regulatory requirements. Trust us for accurate, compliant, and insightful financial solutions tailored to your business needs.',
    },
    {
      id: 7,
      image: '/assets/images/service_imgs/staff training.webp',
      title: 'Professional Staff Training & Business Support',
      description:
        'Enhance your team\'s efficiency with our comprehensive staff training programs. We provide expert training in accounting management, inventory control, sales coordination, production modules, HR operations, and software integration. Our business managerial support ensures your team is equipped with the skills needed for smooth operations and growth. Invest in professional development and optimize your business performance with our tailored training solutions.',
    },
    {
      id: 8,
      image: '/assets/images/service_imgs/liquidity.png',
      title: 'Tax Residency Certificate (TRC) Services',
      description:
        'Obtain your Tax Residency Certificate (TRC) seamlessly with our expert assistance. A TRC is essential for individuals and businesses looking to benefit from UAE’s Double Taxation Avoidance Agreements (DTAA). We handle the complete application process, document preparation, and submission to the Federal Tax Authority (FTA). Whether for international taxation, banking, or compliance purposes, we ensure a smooth and hassle-free experience.',
    },
    {
      id: 9,
      image: '/assets/images/service_imgs/business-man-financial-inspector-secretary-making-report-calculating-checking-balance-internal-revenue-service-inspector-checking-document-audit-concept.webp',
      title: 'Corporate Bank Account Opening & Compliance Services',
      description:
        'Simplify your corporate bank account opening with our expert assistance. We provide end-to-end support, including direct collaboration with banks, document preparation, compliance guidance, and regulatory approvals. Our strong relationships with leading UAE banks ensure a smooth and efficient account setup process. Let us handle the paperwork while you focus on growing your business.',
    },
    {
      id: 10,
      image: '/assets/images/service_imgs/digital-marketing.webp',
      title: 'Digital Marketing & Branding Solutions',
      description:
        'We help businesses grow with expert SEO, social media marketing, Google Ads, website creation, content creation, and branding. Our tailored strategies boost online visibility, drive engagement, and maximize conversions. Whether you need a strong brand identity or a high-performing digital campaign, we deliver results that set you apart.',
    },
    {
      id: 11,
      image: '/assets/images/service_imgs/echeck.png',
      title: 'eCheck – Advanced Screening & Compliance Solution',
      description:
        'eCheck simplifies customer onboarding while ensuring full compliance with KYC and AML regulations. Our powerful identity verification tools provide comprehensive KYC checks, enabling businesses to verify customers quickly and effectively while preventing fraud. Stay compliant, reduce risk, and enhance security with our seamless screening solution.',
    },
    {
      id: 12,
      image: '/assets/images/service_imgs/product.png',
      title: 'Product Registration & Certification Services',
      description:
        'We provide comprehensive support for businesses in securing vital certifications and registrations to ensure compliance and market readiness. Our services include product registration with Dubai Municipality, ESMA certifications, brand name and trademark registration, halal certification, quality certifications, ISO certifications, and ICV certification. With our expertise, we streamline the approval process, ensuring your products and brand meet all regulatory standards and are fully prepared for the market. Success is where preparation and opportunity meet.',
    },
    // {
    //   id: 13,
    //   image: '/assets/images/service_imgs/revenue-operations-concept.png',
    //   title: 'Revenue Certificates',
    //   description:
    //     'Revenue certificates: Govt-issued funds for projects, investors earn fixed returns, funding public initiatives.',
    // },
    // {
    //   id: 14,
    //   image: '/assets/images/service_imgs/managment.png',
    //   title: 'Management Reports',
    //   description:
    //     'Management reports provide essential information for decision-making. Generated regularly, they offer insights into financial performance, operations, and strategy, aiding leaders in informed decision-making and goal alignment.',
    // },
  ];

  return (
    <div className="service5-section-area section-padding1">
      <img src="/assets/images/elementor/elementor26.png" alt="" className="elementoe5" />
      <div className="container" style={{marginTop:'60px'}}>
        <div className="row">
          <div className="col-lg-6 m-auto">
            <div className="service-authors5 text-center margin-b60 mb-0">
              <span className="font-16 font-outfit weight-500 lineh-16 color36 d-inline-block consultingspan margin-b20">
                Services
              </span>
              <h1 className="font-outfit font-44 lineh-52 color37 weight-600 margin-b20">
                What we do!
              </h1>
            </div>
          </div>
        </div>
        <div className="row">
          {servicesData.map((service, index) => (
            <div className="col-lg-4" key={index}>
              <div className="service5-box5area margin-b30">
                <div className="service5img">
                  <img src={service.image} alt="" />
                </div>
                <div className="servicetextimg">
                  <div
                    className="font-outfit font-22 lineh-22 weight-600 color37 d-inline-block margin-b "
                   
                  >
                    {service.title}
                  </div>
                  <p className="font-outfit font-16 lineh-20 weight-400 color38 margin-b16 text-justify">
                    {`${service.description.substring(0, 100)}... `}
                    <span style={{color:'blue',cursor:'pointer'}}
                      className="view-more-link"
                      onClick={() => handleShow(service)} 
                    >
                      Read More
                    </span>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Individual Service Modal */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{selectedService ? selectedService.title : ''}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedService ? selectedService.description : ''}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Understood
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Service;
